import React from 'react';
import { BsWhatsapp, BsInstagram } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';
import { FacebookShareButton, WhatsappShareButton, InstapaperShareButton } from 'react-share';

const SocialMedia = () => (
  <div className="app__social">
    <div>
        <FacebookShareButton
          url='https://www.facebook.com/cybermound/'>
          <FaFacebookF logoFillColor='white' round={true}></FaFacebookF>
        </FacebookShareButton>
    </div>
    <div>
        <WhatsappShareButton
        url='https://api.whatsapp.com/message/CMK2FF3TVO2TP1?autoload=1&app_absent=0'
        >
          <BsWhatsapp logoFillColor='white' round={true}></BsWhatsapp>
        </WhatsappShareButton>
    </div>
    <div>
        <InstapaperShareButton
          url='https://www.instagram.com/cybermound/?utm_medium=copy_link/'
        >
          <BsInstagram logoFillColor='white' round={true}></BsInstagram>
        </InstapaperShareButton>
    </div>
  </div>
);

export default SocialMedia;
