import React from 'react';

import { About, Footer, Header, Skill, Testimonial, Work } from './Container';
import { Navbar } from './Components'
import './App.scss'

const App = () => (
  <div className="app">
    <Navbar />
    <Header />
    <About />
    <Work />
    <Skill />
    <Testimonial />
    <Footer />
  </div>
);

export default App;
